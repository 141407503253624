.mailText textarea {
  width: 100%; 
  height:100%; 
  background-color: #fff; 
  border: none;
  padding: 16px
}

.mailText textarea:focus {
  outline: none !important;
  border: none;
}

#search-detail-menu .MuiMenu-paper {
   overflow: visible !important
}

.react-datepicker-wrapper {
  width: 100%;
}

.rbs-date-field {
  position: relative;
}

/* INPUT - Field */

.rbs-date-field input {
  font-size:16px;
  font-weight: 400;
  width: 100%;
  padding:6px 0px 6px 0px;
  display:block;
  margin-top: 14px;
  border:none;
  border-bottom:1px solid #757575;
  background: transparent;
  &:focus ~ .rbs-date-field label {
    top: -14px;
    font-size: 12px;
    color: green;
  }
}

.rbs-date-field input:focus {
  outline:none;
  border-bottom:1.5px solid #3f51b5;
}

.rbs-date-field input:hover {
  border-bottom-width:2px;
}



.rbs-date-field .disabled {
  color: rgba(0,0,0,0.38);
}

.rbs-date-field .disabled::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.38);
  opacity: 1; /* Firefox */
}

.rbs-date-field .disabled:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(0,0,0,0.38);
}

.rbs-date-field .disabled::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(0,0,0,0.38);
}

.rbs-date-field .disabled input {
  border-bottom:1px dashed #757575;
}
.rbs-date-field .disabled input:hover {
  border-bottom:1px dashed #757575;
}


/* LABEL */

.rbs-date-field label {
  color: rgba(0,0,0,0.54);
  font-size: 10.5px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 0px;
  transition: 0.2s ease all;
}
