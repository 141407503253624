.rbs-page {
    height:'100%';
}

.rbs-flexpage {
    display:'flex';
    flex-flow:'column';
  }


  a:link {
    text-decoration: none;
  }
    
  /* mouse over link */
  a:hover {
    text-decoration: underline;
  }
  
