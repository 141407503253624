html,
body {
	height: 100%;
}

#app{
    height: 100%;
}


.job-inaktiv{
    color: #ccc;
}




.dx-list-item-content {
    padding-left: 10px !important;
}

.dx-button {
    background-color: rgba(191, 191, 191, -0.15);
    border: none;
}

.panel-list {
    height: 400px;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
    float: right;
}

.panel-list .dx-list-item {
    color: #fff;
    border-top: 1px solid rgba(221, 221, 221, .2);
}

.panel-list .dx-list-item .dx-icon {
    color: #fff !important;
}

.options {
    padding: 20px;
    background-color: rgba(191, 191, 191, .15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
    display: inline-block;
    margin-right: 50px;
}

label {
    font-weight: bold;
}

#content {
    height: 100%;
    padding: 10px 20px;
}

#content h2 {
    font-size: 26px;
}