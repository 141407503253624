.rbs-file-vizualizer-main {
    position: relative;
    width: 161px;
    height: 161px;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    background-color: #fafafa;
}

.rbs-file-vizualizer-main:hover {
    background-color: #f3f2f1;
}

.rbs-file-vizualizer-thubnail-container {
    width: 96px;
    height: 96px;
    margin-left: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rbs-foto-thubnail-container {
    float: left; 
    margin: 7px 15px 8px 0;
    width: 117px;
    height: 117px;
    cursor: pointer;
}

.rbs-foto-thubnail-container img:hover {    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



.rbs-file-visualizer-filename {
    padding: 0 12px 6px 12px;
    height: 23px;    
    overflow: hidden;
    word-break: break-all;
}

.rbs-file-vizualizer-filename-complete {
    word-wrap: break-word;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #f3f2f1;
    width: 100%;
    padding: 6px 12px 6px 12px;
}

.rbs-file-visualizer-loading-container {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #ffffff77;
    height: 100%;
    width: 100%;
}

.rbs-file-visualizer-loading-img {
    position: absolute;
    left: 60px;
    top: 50px;
}